.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    margin-top: -60px;
    position: relative;
    background-color: #f4f4f4;
}

.main {
    background-color: white;
    border-radius: 5px;
    box-shadow:
    rgba(145, 158, 171, 0.3) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 30px;
    padding-top: 40px;
    padding-bottom: 40px;
    gap: 25px;
    width: 600px;
}

.title {
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-weight: 500;
    color: #101010;
}

.subtitle {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #000
}

.submitButton {
    width: 100%;
    height: 35px;
}

.submitButton:hover {
    background-color: orange;
    color: white;
}

.textStack {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}

.fieldStack {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.forgotPasswordBox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    
}

.forgotPassword {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    text-decoration: underline;
    color: grey;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.forgotPassword:hover {
    color: orange;
}

.switchForm {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #000;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-weight: 500;
    margin-top: -20px;
    margin-bottom: -10px;
}

.switchForm:hover {
    color: #f57e1f;
}

.textField {
    height: 35px;
}

.svgWave {
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.twoBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px
}