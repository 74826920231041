.custom-cancel-button:hover {
  border-color: darkorange !important;
  color: darkorange !important;
  background-color: transparent !important;
}

.custom-ok-button {
  background-color: #F57D20;
  border-color: #F57D20;
  color: white;
  box-shadow: none;
}

.custom-ok-button:hover {
  background-color: #F57D20 !important;
  border-color: #d95a00 !important;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5) !important;
}