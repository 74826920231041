.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: orange !important;
  border-color: orange !important;
}

.custom-checkbox .ant-checkbox-wrapper:hover .ant-checkbox-inner, 
.custom-checkbox .ant-checkbox:hover .ant-checkbox-inner {
  border-color: darkorange !important;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner:hover {
  background-color: darkorange !important;
  border-color: darkorange !important;
}